.programs-1 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;
}
.programs-header {
  display: flex;
  font-weight: bold;
  gap: 5rem;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
}

.program-categories {
  display: flex;
  gap: 1rem;
}
.category {
  display: flex;
  flex-direction: column;
  background-color: grey;
  padding: 2rem;
  gap: 1rem;
  color: white;
  justify-content: space-between;
}
.category > :nth-child(1) {
  width: 2rem;
  height: 2rem;
  fill: white;
}

.category > :nth-child(2) {
  font-size: 1rem;
  font-weight: bold;
}

.category > :nth-child(3) {
  font-size: 0.9rem;
  line-height: 25px;
}

.join-now {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.join-now > img {
  width: 1rem;
}

.category:hover {
  cursor: pointer;
  background: var(--planCard);
}

@media screen and (max-width: 768px) {
  .programs-1 {
    padding: 0 1rem;
    gap: 1.5rem;
  }

  .programs-header {
    flex-direction: column;
    gap: 1rem;
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
  }

  .program-categories {
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    
  }

  .category {
    padding: 1.5rem;
    width: 100%;
    text-align: center;
  }

  .category > :nth-child(1) {
    width: 1.5rem;
    height: 1.5rem;
  }

  .category > :nth-child(2) {
    font-size: 0.9rem;
  }

  .category > :nth-child(3) {
    font-size: 0.85rem;
    line-height: 22px;
  }

  .join-now {
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }

  .join-now > img {
    width: 1.2rem;
  }
}
