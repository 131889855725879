.footer-container {
  position: relative;
}

.hr {
  border: 1px solid var(--lightgray);
}
.footer {
  display: flex;
  padding: 1rem 2rem;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  height: 20rem;
}
.social-links {
  display: flex;
  gap: 4rem;
}

.social-links > img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.logo > img {
  width: 10rem;
}
.f-blur{
  bottom: 0;
  right: 15%;
  width: 26rem;
  height: 12rem;
  filter: blur(200px);
  background: red;
}
.f-blur2{
bottom: 0;
left: 15%;
width: 26rem;
height: 12rem;
filter: blur(200px);
background: rgb(255, 155, 0);
}
@media screen and (max-width: 768px) {
  .footer {
    padding: 1rem;
    gap: 2rem;
    height: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .social-links {
    gap: 2rem;
  }

  .social-links > img {
    width: 1.5rem;
    height: 1.5rem;
  }

  .logo > img {
    width: 8rem;
  }

  .f-blur {
    width: 20rem;
    height: 8rem;
    bottom: 5%;
    right: 10%;
  }

  .f-blur2 {
    width: 20rem;
    height: 8rem;
    bottom: 5%;
    left: 10%;
  }
}
