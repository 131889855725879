.container-p {
  display: flex;
  gap: 4rem;
  margin-top: -4rem;
  padding: 0 2rem;
  position: relative;
  flex-direction: column;
}

.plan-header {
  display: flex;
  font-weight: bold;
  gap: 2rem;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
}

.Payment-plans {
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;
}
.plan {
  display: flex;
  flex-direction: column;
  background: var(--caloryCard);
  color: white;
  gap: 2rem;
  padding: 1.5rem;
  width: 15rem;
}
.plan:nth-child(2) {
  background: var(--planCard);
  transform: scale(1.1);
}
.plan svg {
  fill: var(--orange);
  width: 2rem;
  height: 2rem;
}
.plan > :nth-last-child(2) {
  font-size: 1rem;
  font-weight: bold;
}
.plan > :nth-child(3) {
  font-size: 3rem;
  font-weight: bold;
}
.plan > :nth-child(5) {
  font-size: 0.8rem;
}

.plan-features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.p-feature {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.p-feature > img {
  width: 1rem;
}

.Payment-plans > :nth-child(2) > svg {
  fill: white;
}
.Payment-plans > :nth-child(2) > button {
  color: orange;
}

.plan > :nth-child(5) > img {
  width: 0.8rem;
  padding: 0rem 0.2rem;
}

.plan-blur {
  width: 32rem;
  height: 23rem;
  top: 6rem;
  nav-left: 0;
}
.plan-blur2 {
  width: 32rem;
  height: 23rem;
  top: 10rem;
  right: 0;
}
@media screen and (max-width: 768px) {
  .container-p {
    gap: 2rem;
    margin-top: 0;
    padding: 0 1rem;
    align-items: center;
  }

  .plan-header {
    font-size: 2rem;
    gap: 1rem;
    flex-direction: column;
    text-align: center;
  }

  .Payment-plans {
    flex-direction: column;
    gap: 2rem;
    align-items: stretch;
  }

  .plan {
    width: 100%;
    padding: 1rem;
  }

  .plan:nth-child(2) {
    transform: scale(1);
  }

  .plan svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .plan > :nth-last-child(2) {
    font-size: 0.9rem;
  }

  .plan > :nth-child(3) {
    font-size: 2rem;
  }

  .plan > :nth-child(5) {
    font-size: 0.7rem;
  }

  .plan-features {
    gap: 0.5rem;
  }

  .p-feature > img {
    width: 0.8rem;
  }

  .plan-blur,
  .plan-blur2 {
    display: none; /* Hide decorative blurs for a cleaner mobile layout */
  }
}
