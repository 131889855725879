.Testimonials {
  display: flex;
  gap: 1rem;
  padding: 0 2rem;
}
.left-t {
  display: flex;
  flex: 1 1;
  gap: 2rem;
  flex-direction: column;
  text-transform: uppercase;
  color: white;
}
.left-t > :nth-child(1) {
  color: orange;
  font-weight: bold;
}

.left-t > :nth-child(2) {
  font-size: 3rem;
  font-weight: bold;
}
.left-t > :nth-child(3) {
  font-size: 3rem;
  font-weight: bold;
}

.tReview {
  text-transform: none;
  text-align: justify;
  letter-spacing: 2px;
  line-height: 40px;
}

.tName {
  color: orange;
}

.right-t {
  flex: 1;
  position: relative;
}

.right-t > img {
  position: absolute;
  width: 17rem;
  height: 20rem;
  object-fit: cover;
  right: 8rem;
  top: 2rem;
}
.right-t > :nth-child(1) {
  position: absolute;
  width: 17rem;
  height: 20rem;
  right: 9rem;
  top: 0.9rem;

  border: 2px solid orange;
  background-color: transparent;
}

.right-t > :nth-child(2) {
  position: absolute;
  width: 17rem;
  height: 19rem;
  background: var(--planCard);
  right: 7rem;
  top: 4rem;
}
.Arrows {
  display: flex;
  position: absolute;
  gap: 1rem;
  bottom: 1rem;
  left: 3rem;
}

.Arrows>img{
    width: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
  .Testimonials {
    flex-direction: column;
    padding: 0 1rem;
    gap: 2rem;
  }

  .left-t {
    gap: 1rem;
    text-align: center;
    align-items: center;
  }

  .left-t > :nth-child(2),
  .left-t > :nth-child(3) {
    font-size: 2rem;
  }

  .tReview {
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    padding: 0 1rem;
  }

  .right-t {
    position: static;
    display: flex;
    justify-content: center;
  }

  .right-t > img,
  .right-t > :nth-child(1),
  .right-t > :nth-child(2) {
    position: static;
    width: 100%;
    height: auto;
    max-width: 15rem;
  }

  .Arrows {
    justify-content: center;
    bottom: -1rem;
    left: 0;
  }

  .Arrows > img {
    width: 1.2rem;
  }
}
